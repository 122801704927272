import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {SideNavButtons} from 'app/main/pages/tarefas-bpms/lic-ambiental/elaborar-parcecer-tecnico/menu/menu.component';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElaborarParcecerTecnicoService implements OnDestroy {

    public requerimentoId:any;
    public tarefaId: any;
    public requerimento: Requerimento;
    

    public onMenuClick$: Subject<any>  = new BehaviorSubject<any>(null);
    private menuClickSub: Subscription;
    
    constructor(private router: Router, private activatedRoute: ActivatedRoute) {

        this.menuClickSub = this.onMenuClick$.subscribe((e) => {
            this.onMenuClickEvent(e);
        });

     }
    
    //@formatter:off

    onMenuClickEvent(menu: SideNavButtons) {
        console.debug('ElaborarParcecerTecnicoService.onMenuClick',menu);
        if (menu == SideNavButtons.INICIO) {
            console.debug('ElaborarParcecerTecnicoService.onMenuClick.inicio');
            return this.router.navigate([`/tarefas-bpms/licenciamento-ambiental/ELABORAR_PARECER_TECNICO/${this.requerimentoId}/${this.tarefaId}`])
                .then((res) => {
                    console.debug('Navigate Home', res);
                });
        }
        if (menu == SideNavButtons.SOLICITACOES) {
            return this.router.navigate([`/tarefas-bpms/licenciamento-ambiental/ELABORAR_PARECER_TECNICO/${this.requerimentoId}/${this.tarefaId}/solicitacoes`])
                .then((res) => {
                    console.debug('Navigate Solicitacoes', res);
                }); 
        }

    }

    ngOnDestroy(): void {
        this.menuClickSub.unsubscribe();
    }
     
}
