import {BaseModel} from "../../../../shared/models/base.model";
import {Requerimento} from "../../../requerimentos/requerimento.model";

export class TermoReferenciaValidacao extends BaseModel {
    constructor(
        public id?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
        public requerimento?: Requerimento
    ) {
        super();
    }

    static fromJson(json: any): TermoReferenciaValidacao {
        json = json || {};

        return new TermoReferenciaValidacao(
            json.id,
            json.dataInicio,
            json.dataFim,
            json.requerimento
        );
    }
}