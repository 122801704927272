import { HttpParams } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { EnumGrupoDocumento } from 'app/main/shared/enums/EnumGrupoDocumento';
import { DocumentoProcesso } from 'app/main/shared/models/documento-processo.model';
import { GerarDocumentoProcessoDTO } from 'app/main/shared/models/gerar-documento-processo.dto.model';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { BaseService } from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable()
export class DocumentoProcessoResolver extends DetailResolver<DocumentoProcesso> {
    constructor(service: DocumentoProcessoService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentoProcessoService extends BaseService<DocumentoProcesso> {

    constructor(injector: Injector) {
        super('/api/documento-processo', injector, DocumentoProcesso.prototype, DocumentoProcesso.fromJson);
    }

    public todosDocumentosEmitidos(httpParams: HttpParams): Observable<any> {
        const url = `${this.urlResource}/page`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    public recuperarDocumentosPorTipoPublicacao(requerimentoId: number): Observable<any> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/publicacao`;
        return this.http.get(url).pipe(
            map(DocumentoProcesso.fromJsons.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    public createMultipartPublicacao(
        multipartFile: Blob,
        documentoProcessoDTO: GerarDocumentoProcessoDTO
    ): Observable<DocumentoProcesso[]> {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoProcesso = new Blob(
            [JSON.stringify(documentoProcessoDTO)],
            {type: 'application/json'}
        );
        formData.append('documentoProcessoDTO', documentoProcesso);
        const url = `${this.urlResource}/criar-documento-publicacao`;
        return this.http
            .post(url, formData)
            .pipe(
                map(DocumentoProcesso.fromJsons.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    downloadDocumentoProcesso(
        documentoProcesso: DocumentoProcesso): Observable<Blob> {
        const url = `${this.urlResource}/visualizar/${documentoProcesso.id}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    readPDFByChave(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/${chave}/pdf`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    public recuperarHtmlSalvo(chave: string): Observable<string> {
        const url = `${this.urlResource}/${chave}/recuperar-html`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    recuperarDocumentoProcessoPorIdRequerimentoESGrupoDocumento(idRequerimento: number, grupo: string): Observable<DocumentoProcesso> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/grupo-documento/${grupo}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscaDocumentoProcessoPorIdRequerimentoEtipo(idRequerimento: number, grupo: EnumGrupoDocumento): Observable<DocumentoProcesso> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tipo/${grupo}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarTodosPorIdRequerimento(idRequerimento: number): Observable<DocumentoProcesso[]> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarDocumentoProcesso(id: number, documentoProcesso: any): Observable<DocumentoProcesso> {
        const url = `${this.urlResource}/${id}`;
        return this.http.put(url, documentoProcesso).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    stringHtmlParaPdf(html: string): Observable<Blob> {
        const url = `${this.urlResource}/html-para-pdf`;
        return this.http.post(url, html, {responseType: 'blob'}).pipe(
            map((res) => {
                return new Blob([res], {type: 'application/pdf'});
            }), catchError(() => this.handleError(this)));
    }

    readPDFVisualizacaoByChave(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/termo-referencia-especifico/${chave}/visualisar`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
        
    }

    //termo-referencia-especifico/{chave}/visualisar
}
