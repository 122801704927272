import {BaseModel} from '../../shared/models/base.model';
import {Finalidade} from '../finalidades/finalidade.model';
import {ObjetivoOutorga} from '../../shared/models/objetivo-outorga.model';
import {Titulacao} from '../titulacoes/titulacao.model';
import {BaseEnum} from '../../shared/enums/base.enum';
import { TipoRequerimento } from '../tipos-requerimento/tipo-requerimento.model';
import { AtividadeArrecadacao } from 'app/main/shared/models/arrecadacao-models/atividade-arrecadacao.model';

export class ConfiguracaoOutorga extends BaseModel {
    constructor(
        public id?: number,
        public tipoAtuacao?: string,
        public finalidade?: Finalidade,
        public objetivoOutorga?: ObjetivoOutorga,
        public titulacao?: Titulacao,
        public tipoRequerimento?: TipoRequerimento,
        public atividadeArrecadacao?: AtividadeArrecadacao,
        public grupoCamunda?: string,
        public idAtividadeArrecadacao?: number
    ) {
        super();
    }

    static tiposAtuacao: BaseEnum[] = [
        {
            valor: 'NOVO',
            descricao: 'Novo'
        },
        {
            valor: 'ANDAMENTO',
            descricao: 'Andamento'
        },
        {
            valor: 'TODOS',
            descricao: 'Todos'
        }

    ];

    static fromJson(json: any): ConfiguracaoOutorga {
        if (json === undefined || json === null) {
            return null;
        }
        return new ConfiguracaoOutorga(
            json.id,
            json.tipoAtuacao,
            Finalidade.fromJson(json.finalidade),
            ObjetivoOutorga.fromJson(json.objetivoOutorga),
            Titulacao.fromJson(json.titulacao),
            TipoRequerimento.fromJson(json.tipoRequerimento),
            AtividadeArrecadacao.fromJson(json.atividadeArrecadacao),
            json.grupoCamunda,
            json.idAtividadeArrecadacao
        );
    }

}
