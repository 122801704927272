import { TermoReferencia } from "app/main/pages/termos-referencia/termo-referencia.model";
import { BaseModel } from "app/main/shared/models/base.model";
import { DocumentoProcesso } from "app/main/shared/models/documento-processo.model";
import { Pessoa } from "app/main/shared/models/pessoa.model";

export class TermoReferenciaEspecificoRequerente extends BaseModel {
    constructor(
        public id?: number,
        public numero?: string,
        public termoReferencia?: TermoReferencia,
        public documentoProcesso?: DocumentoProcesso,
        public requerente?: Pessoa,
        public idRequerenteCorporativo?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
        super();
    }

    static fromJson(json: any): TermoReferenciaEspecificoRequerente {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferenciaEspecificoRequerente(
            json.id,
            json.numero,
            TermoReferencia.fromJson(json.termoReferencia),
            DocumentoProcesso.fromJson(json.documentoProcesso),
            Pessoa.fromJson(json.requerente),
            json.idRequerenteCorporativo,
            json.dataInicio,
            json.dataFim,
        );
    }

    static fromJsons(json: any): TermoReferenciaEspecificoRequerente[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((resource: any) =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }
}