import {BaseModel} from './base.model';

export class GrupoCamunda extends BaseModel {
    constructor(
        public id?: number,
        public name?: string,
        public type?: string
    ) {
        super();
    }

    static fromJson(json: any): GrupoCamunda {
        if (json === undefined || json === null) {
            return null;
        }
        return new GrupoCamunda(
            json.id,
            json.name,
            json.type
        );
    }
}
