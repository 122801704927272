import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { ServiceAssinador } from 'app/main/shared/services/service-assinador.interface';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TermoReferenciaValidacaoSignatario } from '../model/termo-referencia-validacao-signatario.model';

@Injectable({
    providedIn: 'root'
})
export class TermoReferenciaValidacaoSignatarioService extends BaseService<TermoReferenciaValidacaoSignatario>   implements ServiceAssinador {

    constructor(protected injector: Injector) {
        super(
            '/api/termo-referencia-validacao-signatario',
            injector,
            TermoReferenciaValidacaoSignatario.prototype,
            TermoReferenciaValidacaoSignatario.fromJson
        );
    }

    buscaListaSignatariosTr(idRequerimento: number): Observable<TermoReferenciaValidacaoSignatario[]> {
        const url = `${this.urlResource}/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    buscaTrValidacaoSignatarioPorCpfServidor(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/busca-termo-validacao-signatario/${idRequerimento}`;
        return this.http.get(url);
    }

    updateTrValidacaoSignatario(termoReferenciaValidacaoSignatario: TermoReferenciaValidacaoSignatario): Observable<TermoReferenciaValidacaoSignatario> {
        const url = `${this.urlResource}/atualizar-tr-validacao-signatario`;
        return this.http.put(url, termoReferenciaValidacaoSignatario)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    assinarDocumento(alvoAssinador: any, senha: string, idTarefa?: string): Observable<any> {
        const url = `${this.urlResource}/assinar-termo-referencia-especifico`;
        return this.http.post(url, alvoAssinador, {params: {idTarefa: idTarefa, senha: btoa(senha)}}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    assinarDocumentoCodigoVerificador(alvoAssinador: any, senha: string, idTarefa?: string){
        return this.http.post('', {});
    }
}
