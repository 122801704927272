import {BaseModel} from "../../../../shared/models/base.model";
import {Servidor} from "../../../../shared/models/servidor.model";
import { TermoReferenciaEspecificoRequerente } from "./termo-referencia-especifico-requerente.model";
import {TermoReferenciaValidacao} from "./termo-referencia-validacao.model";

export class TermoReferenciaValidacaoSignatario extends BaseModel {
    constructor(
        public id?: number,
        public servidor?: Servidor,
        public dataAvaliacao?: Date,
        public avaliacao?: string,
        public comentario?: string,
        public termoReferenciaValidacao?: TermoReferenciaValidacao,
        public tarefaFinalizada?: boolean,
        public termoReferenciaAssinado?: boolean,
        public termoReferenciaEspecificoRequerente?: TermoReferenciaEspecificoRequerente,
    ) {
        super();
    }

    static fromJson(json: any): TermoReferenciaValidacaoSignatario {
        json = json || {};

        return new TermoReferenciaValidacaoSignatario(
            json.id,
            Servidor.fromJson(json.servidor),
            json.dataAvaliacao,
            json.avaliacao,
            json.comentario,
            TermoReferenciaValidacao.fromJson(json.termoReferenciaValidacao),
            json.tarefaFinalizada,
            json.termoReferenciaAssinado,
            TermoReferenciaEspecificoRequerente.fromJson(json.termoReferenciaEspecificoRequerente)
        );
    }
}