import {Injectable, Injector} from '@angular/core';
import {ParecerTecnico} from './parecer-tecnico.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {EmissaoTitulo} from 'app/main/shared/models/emissao-titulo.model';
import { HttpHeaders, HttpParams } from '@angular/common/http'; 
import { ParecerTecnicoAnexo } from './parecer-tecnico-anexo.model'; 
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { RequerimentoService } from 'app/main/pages/requerimentos/requerimento.service';
import { environment } from 'environments/environment';

@Injectable()
export class ParecerTecnicoListResolver extends ListResolver<ParecerTecnico> {
    constructor(service: ParecerTecnicoService) {
        super(service);
    }
}

@Injectable()
export class ParecerTecnicoResolver extends DetailResolver<ParecerTecnico> {
    constructor(
        service: ParecerTecnicoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ParecerTecnicoService extends BaseService<ParecerTecnico> {
    
    constructor(protected injector: Injector) {
        super(
            '/api/pareceres-tecnicos',
            injector,
            ParecerTecnico.prototype,
            ParecerTecnico.fromJson
        );
    }

    finalizarTarefaCamunda(idRequerimento, idTarefa): Observable<any> {
        const url = `${this.urlResource}/concluir-tarefa/${idRequerimento}/${idTarefa}/`;
        return this.http.post(url, null).pipe(map(response => response),
            catchError(this.handleError)
        );
    }

    salvarHtmlDocumento(html: string, idParecer: number): Observable<EmissaoTitulo> {
        const url = `${this.urlResource}/${idParecer}/salvar-html-documento`;
        return this.http.post(url, html).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    readPdf(parecerTecnico: ParecerTecnico): Observable<any> {
        return this.http.get(`${this.urlResource}/${parecerTecnico.id}/pdf`, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    readMinuta(parecerTecnico: ParecerTecnico): Observable<any> {
        return this.http.get(`${this.urlResource}/${parecerTecnico.id}/minuta`, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    public recuperarHtmlSalvo(parecerTecnico: ParecerTecnico): Observable<string> {
        const url = `${this.urlResource}/${parecerTecnico.id}/recuperar-html`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public listarAnexos(parecerTecnicoId: any) {
        const url = `${this.urlResource}/anexos`;
        let params = new HttpParams();
        params = params.append('parecerTecnicoId', parecerTecnicoId);
        return this.http.get(url,{ params: params }).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public excluirAnexo(anexo: ParecerTecnicoAnexo) {
        const url = `${this.urlResource}/anexo/${anexo.id}`;
        return this.http.delete(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public enviarAnexo(anexos: ParecerTecnicoAnexo[] | any[]): Observable<any>[] {
        const url = `${this.urlResource}/anexo`;
        const results: Observable<any>[] =  [];
        if (anexos) {
            anexos.forEach(async (anexo: any) => {
                const formData = new FormData();
                const { multipartFile, ...dto } = anexo;
                const paramDto = new Blob(
                    [JSON.stringify( dto )],
                    {type: 'application/json'}
                );
                formData.append('multipartFile', anexo.multipartFile);
                formData.append('anexoDTO', paramDto);  
                results.push(
                    this.http.post(url, formData).pipe(
                        map(response => response),
                        catchError(this.handleError)
                    )
                );
                 
            });
        }
        return results;
        
        
    }

    public visualizarAnexo(id: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(`${this.urlResource}/anexo/${id}/download`, { headers: headers, responseType: 'blob' });        
    }

    updateForLicenciamentoAmbientalAnalise(resource: ParecerTecnico): Observable<ParecerTecnico> {
        const url = `${this.urlResource}/${resource.id}/licenciamento-ambiental/analise`;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public getByRequerimento(requerimento: Requerimento) {
        const url = `${RequerimentoService.URL_RESOURCE}/${requerimento.id}/parecer-tecnico`;
        return this.http
            .get(url)
            .pipe(
                map(ParecerTecnico.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
