import {Component, OnInit} from "@angular/core";
import {ServidorService} from "app/main/shared/services/servidor.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {ElaborarParcecerTecnicoService} from "../elaborar-parcecer-tecnico.service";

export const tarefaSteps = {
    TAREFA: 0,
    REQUERIMENTO: 1,
    IMOVEL: 2,
    RESPONSAVEIS: 3,
    DADOS_GEOGRAFICOS: 4,
    ATIVIDADE: 5,
    DOCUMENTACAO_COMPLEMENTAR: 6,
};

export enum SideNavButtons {
    MAPA_GEO,
    TAREFA,
    HISTORICO,
    NOTAS,
    COMPLEMENTACAO,
    GESTAO_MANIFESTACAO,
    REVISAO_REQUERIMENTO,
    SOLICITACOES,
    INICIO,
}

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
    sideNavSelected = SideNavButtons.TAREFA;
    sideNavButtons = SideNavButtons;
    nomeTarefa: string;
    isServidor: boolean = undefined;

    constructor(
        public service: ElaborarParcecerTecnicoService,
        private servidorService: ServidorService,
        
    ) {}

    async ngOnInit() {
        this.servidorService.isUsuarioLogadoServidor()
        .pipe(map((response) => {
            this.isServidor = response.servidor;
        })).toPromise().then();
    }

    async onChangeButtonGroup(): Promise<void> {
        console.debug(
            "Menucomponent.onChangeButtonGroup",
            this.sideNavSelected
        ); 
        this.service.onMenuClick$.next(this.sideNavSelected);
    }

    public get isUsuarioServidor(): Observable<boolean> {
        return this.servidorService.isUsuarioLogadoServidor()
            .pipe(map((response) => {
                return  response.servidor;
            }));
         
    }
}
